import * as React from "react"
import { FC } from "react"
import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutPage from "../social-supermarket/pages/checkout/CheckoutPage"
import { getConfig } from "../config"

const stripePromise = loadStripe(getConfig().stripeKey)

interface Props {}

const Checkout: FC<Props> = ({}) => (
  <Elements stripe={stripePromise}>
    <CheckoutPage />
  </Elements>
)

export default Checkout
